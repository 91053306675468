<template>
  <div class="card">
    <div class="card-body">
      <AccountUnverified v-if="!user.email_verified_at" />
      <div v-else>
        <table class="table">
          <colgroup>
            <col width="50%">
            <col width="50%">
          </colgroup>
          <thead>
            <tr>
              <th>Services</th>
              <th>Used/Total</th>
            </tr>
          </thead>
          <tbody v-if="usage">
          <tr v-for="(value, index) in usage" :key="index">
              <td>{{index}}</td>
              <td>{{value}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import profileApi from '../../../api/profileApi'

export default {
  data () {
    return {
      usage: null,
      error: false
    }
  },

  mounted () {
    this.getUsage()
  },

  methods: {
    async getUsage () {
      if (this.user.email_verified_at && this.user.role === 'admin') {
        try {
          this.usage = await profileApi.getAccountUsage()
        } catch {
          this.error = true
        }
      }
    }
  },

  computed: {
    ...mapState('authentication', ['user'])
  }
}
</script>

<style lang="scss" scoped>
  .navigation {
    display: flex;

    .link {
      padding: 8px;

      &:first-child {
        padding-left: 0;
      }
    }
  }
</style>
